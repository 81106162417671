<template>
  <div class="page">
    <div class="print" style="font-size: 36px">
      {{ $t("param.a2") }}
      <el-button v-print="'printObj'" type="primary" @click="printClick"
        v-loading.fullscreen.lock="fullscreenLoading">{{ $t('common.a12') }}
      </el-button>
    </div>
    <!--打印区域-->
    <div id="print_html" ref="print_html">
      <!-- button -->
      <div class="tool-btn" v-show="edit == 'true'">
        <!--权限控制-->
        <el-button size="large" type="primary" v-if="quanxian == 'Config' || quanxian == 'Admin'" v-show="disabled"
          @click="disabled = !disabled">{{ $t('common.a3') }}
        </el-button>
        <el-button size="large" type="primary" v-show="!disabled" @click="saveEdit">{{ $t('common.a4') }}
        </el-button>
      </div>
      <el-form ref="form" :model="form" :rules="rules" :disabled="disabled">
        <ul class="param-set">
          <!-- <li>
            <div>{{$t('param.a11')}}：</div>
            <div>
              <el-input
                v-model="form.filename"
                :maxlength="20"
                class="input-set"
                size="small"
              ></el-input>
            </div>
          </li> -->
          <li>
            <div>{{ $t('param.a12') }}：</div>
            <div>
              <el-input v-model="form.name" :maxlength="20" class="input-set" size="small"
                @input="validateFileName"></el-input>
            </div>
          </li>
          <li>
            <div>{{ $t('param.a13') }}：</div>
            <div>
              <el-input v-model="form.version" :maxlength="20" @input="getinput('version')" class="input-set"
                size="small"></el-input>
            </div>
          </li>
          <li>
            <div>{{ $t('param.a14') }}：</div>
            <div>
              <el-input v-model="form.super_password" :maxlength="20" @input="getinput('super_password')"
                class="input-set" size="small"></el-input>
            </div>
          </li>
          <li>
            <div>{{ $t('param.a15') }}：</div>
            <div style="position: relative;">
              <el-select v-model="form.wallpaper" @change="checkBg" :placeholder="$t('common.a9')">
                <el-option v-for="(item) in bgImgList" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
              <div style="position: absolute;top:-20px;left: 240px;" @click="dialogVisible = true">
                <el-image v-if="form.wallpaper" style="width: 70px;" :src="bgImg" fit="contain"></el-image>
              </div>
            </div>
          </li>
          <li>
            <div>{{ $t('param.a16') }}：</div>
            <div>
              <el-checkbox v-model="form.virtual_channel_enable" @change="virtualChannelEnableChange"></el-checkbox>
            </div>
          </li>
          <li>
            <div>{{ $t('param.a17') }}：</div>
            <div>
              <el-checkbox :disabled="!form.virtual_channel_enable" v-model="form.virtual_channel_popwin"></el-checkbox>
            </div>
          </li>
          <li>
            <div>{{ $t('param.a18') }}：</div>
            <div>
              <el-checkbox v-model="form.ptt_key_to_group_call_list_enable"></el-checkbox>
            </div>
          </li>
          <li>
            <div>{{ $t('param.a19') }}：</div>
            <div class="slider-item">
              <div>
                <el-slider v-model="form.screen_brightness_dim_value" :max="10" style="width: 120px"></el-slider>
                <ul class="flex dian">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
              <div class="icon-lft">
                <img src="../../assets/img/icon-img/brightj.png" alt="" />
              </div>
            </div>
          </li>
          <li>
            <div>{{ $t('param.a20') }}：</div>
            <div>
              <el-input v-model="form.gsmr_function_code" oninput="value=value.replace(/[^\d]/g,'').substr(0,2)"
                class="input-set" size="small"></el-input>
            </div>
          </li>
          <li>
            <div>{{ $t('param.a21') }}：</div>
            <div class="slider-item">
              <div>
                <el-slider v-model="form.ring_volume" :max="7" @change="checkRingVol" style="width: 160px"></el-slider>
                <ul class="flex dian">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
              <div class="icon-lft">
                <img src="../../assets/img/icon-img/shengyin_shiti.png" style="transform: rotate(180deg);" alt="" />
              </div>
            </div>
          </li>
          <li>
            <div>{{ $t('param.a22') }}：</div>
            <div class="slider-item">
              <div>
                <el-slider v-model="form.min_volume" :max="7" @change="checkMinVol" style="width: 160px"></el-slider>
                <ul class="flex dian">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div>{{ $t('param.a23') }}：</div>
            <div>
              <el-checkbox v-model="form.vibrate"></el-checkbox>
            </div>
          </li>
          <li>
            <div>{{ $t('param.a24') }}：</div>
            <div style="display: flex;">
              <!-- <img src="../../assets/img/icon-img/yinle.png" alt="" /> -->
              <el-select v-model="form.p2p_ring" @change="checkBGM('p2p_ring')" :placeholder="$t('common.a9')">
                <el-option v-for="(item) in radioList" :key="item.name" :label="item.value" :value="item.value">
                </el-option>
              </el-select>
              <audio v-if="p2p_ring" :key="p2p_ring" style="height: 40px;" controls
                controlsList="nodownload  noplaybackrate">
                <source :src="p2p_ring">
              </audio>
            </div>
          </li>
          <li>
            <div>{{ $t('param.a25') }}：</div>
            <div style="display: flex;">
              <!-- <img src="../../assets/img/icon-img/yinle.png" alt="" /> -->
              <el-select v-model="form.vgcs_ring" @change="checkBGM('vgcs_ring')" :placeholder="$t('common.a9')">
                <el-option v-for="(item) in radioList" :key="item.name" :label="item.value" :value="item.value">
                </el-option>
              </el-select>
              <audio v-if="vgcs_ring" :key="vgcs_ring" style="height: 40px;" controls
                controlsList="nodownload  noplaybackrate">
                <source :src="vgcs_ring">
              </audio>
            </div>
          </li>
          <li>
            <div>{{ $t('param.a33') }}：</div>
            <div>
              <el-checkbox v-model="form.initiate_rec"></el-checkbox>
            </div>
          </li>
          <li>
            <div>{{ $t('param.rec_number') }}：</div>
            <div>
              <el-input v-model="form.rec_number" :maxlength="3" @input="getinput('rec_number')"
                class="input-set" size="small"></el-input>
            </div>
          </li>
          <li>
            <div>{{ $t('param.a34') }}：</div>
            <div>
              <el-input v-model="form.engine_number" :maxlength="10" @input="getinput('engine_number')"
                class="input-set" size="small"></el-input>
            </div>
          </li>
          <li>
            <div>{{ $t('param.a35') }}：</div>
            <div>
              <el-input v-model="form.coach_number" :maxlength="10" @input="getinput('coach_number')" class="input-set"
                size="small"></el-input>
            </div>
          </li>
          <li>
            <div style="line-height: 26px;">{{ $t('param.a36') }}：</div>
            <div>
              <el-input v-model="form.delay_seconds_before_auto_answer_call"
                @input="getinput('delay_seconds_before_auto_answer_call')" maxlength="2" @change="checkAnswerCall"
                class="input-set" size="small"></el-input>
            </div>
          </li>
          <li>
            <div>{{ $t('param.a39') }}：</div>
            <div style="position: relative;">
              <el-select v-model="form.screen_off_timeout" :placeholder="$t('common.a9')">
                <el-option v-for="(item) in auto_keypad_lock_timeout_list" :key="item.value" :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
          </li>
        </ul>
      </el-form>

      <div style="height: 100px"></div>
      <!-- button -->
      <div class="tool-btn next-btn" v-show="edit != 'true'">
        <el-button size="large" type="primary" @click="save">{{ $t('common.a6') }}</el-button>
      </div>

      <el-dialog :visible.sync="dialogVisible" width="30%" top="5vh">
        <img width="100%" :src="bgImg" alt="">
      </el-dialog>
    </div>
    <!--打印区域-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      version: "",
      password: "",
      fileNameRegex: /^[a-zA-Z0-9-_]{1,20}$/, // Regular expression for valid filename characters
      value1: 30,
      disabled: false,
      edit: false,
      form: {
        name: "",
        id: "",
        filename: "",
        version: "",
        super_password: "",
        wallpaper: 'default_wallpaper_2',
        virtual_channel_enable: false,
        virtual_channel_popwin: false,
        ptt_key_to_group_call_list_enable: false,
        screen_brightness_dim_value: 5,
        gsmr_function_code: "01",
        ring_volume: 7,
        min_volume: 0,
        vibrate: false,
        p2p_ring: 'Flutey Phone',
        vgcs_ring: 'Group1',
        initiate_rec: true,
        rec_number: '299',
        engine_number: '',
        coach_number: '',
        delay_seconds_before_auto_answer_call: 4,
        screen_off_timeout: 2147483647
      },
      rules:{
        name: [
          // { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 1, max: 20, message: '', trigger: 'blur' }
        ],
        rec_number: [
          { required: true, message: this.$t('common.number_only'), trigger: 'blur' },
          {min: 0, max: 3, message: '', trigger: 'blur'}
        ],
      },
      p2p_ring: "",
      vgcs_ring: "",
      bgImg: require('../../../static/phone-bgimg/default_wallpaper_2.png'),
      dialogVisible: false,
      bgImgList: [
        'default_wallpaper_2',//.png
        'wallpaper_fc',//.png
        'wallpaper_second',//.png
        'wallpaper_third',//.png
        'wallpaper_one',//.png
        'wallpaper_four'//.png
      ],
      radioList: [
        {value: 'Flutey Phone', name: "Flutey Phone.ogg",},
        {value: 'Group1', name: "Group1.mp3",},
        {value: 'Group2', name: "Group2.mp3",},
        {value: 'ptt_alert', name: "ptt_alert.wav",},
        {value: 'Ringtone1', name: "Ringtone1.mp3",},
        {value: 'Ringtone2', name: "Ringtone2.mp3",},
        {value: 'Ringtone3', name: "Ringtone3.mp3",},
        {value: 'Ringtone4', name: "Ringtone4.mp3",},
        {value: 'Ringtone5', name: "Ringtone5.mp3",},
        {value: 'Shutdown', name: "Shutdown.mp3",},
        {value: 'Bootes', name: "Bootes.ogg",},
        {value: 'Bootmusic', name: "Bootmusic.mp3",},
        {value: 'Broadcast1', name: "Broadcast1.mp3",},
        {value: 'Broadcast2', name: "Broadcast2.mp3",},
        {value: 'Cairo', name: "Cairo.ogg",},
        {value: 'Emergency-fr', name: "Emergency-fr.wav",},
        {value: 'Emergency-fr_Chantier', name: "Emergency-fr_Chantier.wav",},
        {value: 'Emergency1', name: "Emergency1.mp3",},
        {value: 'Emergency2', name: "Emergency2.wav",},
      ],
      auto_keypad_lock_timeout_list: [
      {value: 2147483647, name: this.$t('param.a40')},
      {value: 15000, name: "15"+this.$t('common.seconds')},
      {value: 30000, name: "30"+this.$t('common.seconds')},
      {value: 60000, name: "1"+this.$t('common.minute')},
      {value: 120000, name: "2"+this.$t('common.minutes')},
      {value: 300000, name: "5"+this.$t('common.minutes')},
      {value: 600000, name: "10"+this.$t('common.minutes')},
      {value: 1800000, name: "30"+this.$t('common.minutes')},
      ],
      quanxian: '',
      printObj: {
        id: 'print_html', //打印标签的id
      },
      fullscreenLoading: false,
    };
  },
  watch: {
    edit(o) {
      console.log(o != true, this.edit != true, this.edit, '又改变了')
    }
  },
  created() {
    let data = sessionStorage.getItem("file");
    if (data) {// 编辑情况下的赋值
      Object.assign(this.form, JSON.parse(data));
      this.form.virtual_channel_enable = (this.form.virtual_channel_enable == 'true' || this.form.virtual_channel_enable === true) ? true : false
      this.form.virtual_channel_popwin = (this.form.virtual_channel_popwin == 'true' || this.form.virtual_channel_popwin === true) ? true : false
      this.form.ptt_key_to_group_call_list_enable = (this.form.ptt_key_to_group_call_list_enable == 'true' || this.form.ptt_key_to_group_call_list_enable === true) ? true : false
      this.form.vibrate = (this.form.vibrate == 'true' || this.form.vibrate === true) ? true : false
      this.form.min_volume = Number(this.form.min_volume)
      this.form.ring_volume = Number(this.form.ring_volume)
      this.form.p2p_ring = this.form.p2p_ring;
      this.form.vgcs_ring = this.form.vgcs_ring;
      this.form.wallpaper = this.form.wallpaper;
      this.form.screen_brightness_dim_value = Number(
          this.form.screen_brightness_dim_value
      );
      this.form.initiate_rec = (this.form.initiate_rec == 'true' || this.form.initiate_rec === true) ? true : false
      this.form.rec_number = this.form.rec_number;
      this.form.engine_number = this.form.engine_number;
      this.form.coach_number = this.form.coach_number;
      this.form.delay_seconds_before_auto_answer_call = Number(this.form.delay_seconds_before_auto_answer_call);
      this.form.screen_off_timeout = this.form.screen_off_timeout;
      this.checkBg();
    }
    this.checkBGM('p2p_ring');
    this.checkBGM('vgcs_ring');

    let user = sessionStorage.getItem('user');
    if (user) this.quanxian = JSON.parse(user).quanxian;
  },
  mounted() {
    this.edit = sessionStorage.getItem("edit");
    if (this.edit == 'true') this.disabled = true;
  },
  beforeRouteLeave(to, from, next) {
    const lastTime = sessionStorage.getItem('control')
    if (this._logoutIfTimeout(lastTime)){
      next()
      return;
    }
    if (this.edit == 'true' && this.disabled == false) {// 如果是编辑的时候没有保存，加一个提示
      this.$confirm(this.$t('message.a13'), this.$t('message.a10'), {
        confirmButtonText: this.$t('common.a4'),
        cancelButtonText: this.$t('cp.a5'),
        type: 'warning'
      }).then(() => {
        if (this.save(
          res =>{
            next();
          }
          ) === false) {
          next(false)
        } else {
          //next();
        }
      }).catch(() => {
        next()
      });
    } else if (this.edit == 'true' || (
        to.path == '/Contacts' ||
        to.path == '/BasicParameters' ||
        to.path == '/Menus' ||
        to.path == '/Shortcutkeys' ||
        to.path == '/ForbiddenNumbers'
    )) {
      return next();
    } else {
      this.$confirm(this.$t('message.a11'), this.$t('message.a10'), {
        confirmButtonText: this.$t('cp.a4'),
        cancelButtonText: this.$t('cp.a5'),
        type: 'warning'
      }).then(() => {
        if (this.form.id) {
          this.postRequest('index/del_phone_profile', {ids: this.form.id}, () => {
            this.$message({
              type: 'success',
              message: this.$t('message.a6')
            });
          })
        }
        next();
      }).catch(() => {
        next(false)
      });
    }
  },
  methods: {
    printClick() {
      this.fullscreenLoading = true;
      this.html2canvas(this.$refs.print_html, {
        backgroundColor: null,
        useCORS: true,
        windowHeight: document.body.scrollHeight
      }).then((canvas) => {
        const url = canvas.toDataURL()
        this.printJS({
          printable: url,
          type: 'image',
          documentTitle: ''
        })
        this.fullscreenLoading = false;
      });
    },
    checkBg() {
      this.bgImg = require('../../../static/phone-bgimg/' + this.form.wallpaper + '.png');
    },
    checkBGM(key) {
      this.radioList.find(res => {
        if (res.value == this.form[key]) {
          this[key] = require('../../../static/background-music/' + res.name);
          return true;
        }
      })
    },
    saveEdit() {
      const lastTime = sessionStorage.getItem('control')
      if (this.logoutIfTimeout(lastTime)) {
        return;
      } else {
        this.disabled = false;
        this.save();
      }
    },
    save(callback) {
      if (!this.form.name) {
        this.$message({
          type: 'warning',
          message: this.$t('param.a7')
        })
        return false;
      }
      if (!this.fileNameRegex.test(this.form.name)) {
        this.$message({
          message: this.$t('param.invalid_file_name'),
          type: 'error'
        });
        return false;
      }
      
      if (!this.form.version) {
        this.$message({
          type: 'warning',
          message: this.$t('message.a16')
        })
        return false;
      }
      if (!this.form.super_password) {
        this.$message({
          type: 'warning',
          message: this.$t('message.a17')
        })
        return false;
      }
      if (this.form.initiate_rec && !this.form.rec_number){
        this.$message({
          type: 'warning',
          message: this.$t('message.rec_number_required')
        })
        return false;
      }
      this.postRequest(
          "index/add_phone_profile",
          {
            uid: sessionStorage.getItem("id"),
            ...this.form,
            virtual_channel_enable: this.form.virtual_channel_enable.toString(),
            virtual_channel_popwin: this.form.virtual_channel_popwin.toString(),
            ptt_key_to_group_call_list_enable: this.form.ptt_key_to_group_call_list_enable.toString(),
            vibrate: this.form.vibrate.toString(),
            initiate_rec: this.form.initiate_rec.toString(),
            filename: this.form.name
          },
          (res) => {
            if (this.form.id && this.edit == 'true') {
              sessionStorage.setItem("file", JSON.stringify(this.form));
              // this.$message({
              //   type: "success",
              //   message: this.$t('message.a5'),
              // });
              this.uploadXML();
              this.disabled = true;
            } else {
              sessionStorage.setItem(
                  "file",
                  JSON.stringify({
                    ...this.form,
                    id: res.data,
                  })
              );

              this.$router.push("/Contacts");
              this.$root.status = 'Contacts';
            }
            if (callback){
              callback()
            }
          },
          true
      );
    },
    uploadXML() {
      this.postRequest('index/create_xml', {
        id: this.form.id,
        uid: sessionStorage.getItem('id')
      }, () => {
        this.$message({
          type: 'success',
          message: this.$t('message.a5')
        });
      })
    },
    getinput(key) {
      this.form[key] = this.form[key].replace(/[^\d^\.]+/g, '');
    },
    checkAnswerCall() {
      this.form.delay_seconds_before_auto_answer_call = Number(this.form.delay_seconds_before_auto_answer_call).toString()
      if (Number(this.form.delay_seconds_before_auto_answer_call) > 20) this.form.delay_seconds_before_auto_answer_call = '20'
    },
    virtualChannelEnableChange() {
      if (!this.form.virtual_channel_enable) this.form.virtual_channel_popwin = false;
    },
    checkRingVol() {
      if (this.form.ring_volume < this.form.min_volume) {
        this.form.min_volume = this.form.ring_volume
      }
    },
    checkMinVol() {
      if (this.form.ring_volume < this.form.min_volume) {
        this.form.ring_volume = this.form.min_volume
      }
    },
    validateFileName() {
      if (this.form.name && !this.fileNameRegex.test(this.form.name)) {
        this.$message({
          message: this.$t('param.invalid_file_name'),
          type: 'error'
        });
      }
    }
  }
};
</script>

<style>
.input-set .el-input__inner {
  border: 1px solid #999999;
  border-radius: 10px;
  text-align: center;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
</style>
<style scoped>
.page {
  position: relative;
}

.param-set > li {
  display: flex;
  line-height: 46px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}

.param-set > li > div {
  width: 50%;
}

.input-set {
  width: 210px;
  /* width: 124px; */
}

.slider-item {
  display: flex;
  height: 40px;
  padding-top: 10px;
  box-sizing: border-box;
}

.slider-item > .icon-lft {
  margin-left: 15px;
  display: flex;
  align-items: center;
}

.dian {
  width: 100%;
  margin-top: -10px;
}

.dian > li {
  width: 1px;
  height: 5px;
  background: #979797;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.tool-btn {
  text-align: right;
}

.next-btn {
  position: absolute;
  right: 45px;
  bottom: 30px;
}

.table {
  margin: 30px 0;
}

.print {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
</style>

<style media="print_html">
@page {
  margin: 3mm 10mm; /* this affects the margin in the printer settings */
}
</style>